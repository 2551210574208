import cx from 'classnames';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DefaultAccountLayout } from '../../components/common/DefaultLayout';
import { AUTHORITY_GROUP_LEVELS, useAuthorityGroupLevel } from '../../hooks/authority-group-level';
import Dropzone from 'react-dropzone';
import { FormDateInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormDateInput';
import { useForm, useWatch } from 'react-hook-form';
import BasicButton from '@archinsurance-viki/property-jslib/src/components/buttons/BasicButton';
import { TabPanelHeaderTemplateOptions, TabView, TabPanel } from 'primereact/tabview';

import { BodyCell, HeaderCell, HeaderRow, Table, TableRow } from '@archinsurance-viki/property-jslib/src/components/table/Table';
import Tooltip from '@archinsurance-viki/property-jslib/src/components/widgets/Tooltip';

export const DragNDrop = ({ children, onDrop }: { children: React.ReactNode; onDrop: (files: File[]) => void }) => {
    return (
        <Dropzone noClick={true} onDrop={(acceptedFiles: File[]) => onDrop(acceptedFiles)}>
            {({ getRootProps }) => (
                <div {...getRootProps()} className="drop-container">
                    {children}
                </div>
            )}
        </Dropzone>
    );
};

export const BulkCatModelPage = () => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const tabTemplate = (options: TabPanelHeaderTemplateOptions) => {
        return (
            <div onClick={options.onClick} className={cx(options.className, 'table-pref', { active: options.selected })}>
                <i className="pi pi-prime p-mr-2" />
                {options.titleElement}
            </div>
        );
    };
    return (
        <DefaultAccountLayout hasActionPanel={false}>
            <div className="tw-items-center tw-p-2 tw-max-w-[1200px] [&_.p-tabview-nav-container]:tw-max-w-[600px]">
                <TabView panelContainerClassName="tw-mt-4" activeIndex={activeTabIndex} onTabChange={e => setActiveTabIndex(e.index)}>
                    <TabPanel header="Schedule New Job" headerTemplate={tabTemplate}>
                        <BulkCatModelScheduling />
                    </TabPanel>
                    <TabPanel header="Bulk Cat Model Jobs" headerTemplate={tabTemplate}>
                        <BulkCatModelJobs />
                    </TabPanel>
                </TabView>
            </div>
        </DefaultAccountLayout>
    );
};

const DEFAULT_FILTERS = {
    status: {
        Scheduled: true,
        'In Progress': true,
        Completed: true,
    },
};

export const BulkCatModelJobs = () => {
    const jobs = [
        { id: 1, status: 'Scheduled', date_submitted: '8/12/2024', date_scheduled: '8/20/2024', user: 'Rob Gries', file_name: 'bulk_cat_model_8_20_2024.csv' },
        { id: 1, status: 'Completed', date_submitted: '8/10/2024', date_scheduled: '8/11/2024', user: 'Rob Gries', file_name: 'bulk_cat_model_8_11_2024.csv' },
    ];
    const [filters, setFilters] = useState({ ...DEFAULT_FILTERS });
    return (
        <div className="tw-flex tw-flex-col tw-gap-4">
            <h2 className="tw-text-lg">Bulk Cat Model Jobs</h2>
            <div className="tw-flex tw-flex-col tw-gap-2">
                <h3>Filters</h3>
                <div className="tw-flex tw-gap-6 tw-text-base">
                    <div className="tw-flex tw-gap-2">
                        <label>Scheduled</label>
                        <input
                            type="checkbox"
                            checked={filters.status.Scheduled}
                            onChange={() => setFilters(prev => ({ ...prev, status: { ...prev.status, Scheduled: !prev.status.Scheduled } }))}
                        />
                    </div>
                    <div className="tw-flex tw-gap-2">
                        <label>In Progress</label>
                        <input
                            type="checkbox"
                            checked={filters.status['In Progress']}
                            onChange={() => setFilters(prev => ({ ...prev, status: { ...prev.status, 'In Progress': !prev.status['In Progress'] } }))}
                        />
                    </div>
                    <div className="tw-flex tw-gap-2">
                        <label>Completed</label>
                        <input
                            type="checkbox"
                            checked={filters.status.Completed}
                            onChange={() => setFilters(prev => ({ ...prev, status: { ...prev.status, Completed: !prev.status.Completed } }))}
                        />
                    </div>
                </div>
            </div>
            <Table className="tw-grid-cols-[150px_150px_1fr_2fr_100px] items-center tw-w-full tw-max-h-[800px] tw-overflow-auto">
                <HeaderRow>
                    <HeaderCell>Status</HeaderCell>
                    <HeaderCell>Date Submitted</HeaderCell>
                    <HeaderCell>User</HeaderCell>
                    <HeaderCell>File Name</HeaderCell>
                    <HeaderCell />
                </HeaderRow>
                {jobs?.map(bulkCatModelJob => {
                    let tooltip: React.ReactNode = '';
                    if (bulkCatModelJob.status === 'Scheduled') {
                        tooltip = <span>Scheduled Date: {bulkCatModelJob.date_scheduled}</span>;
                    } else if (bulkCatModelJob.status === 'Completed') {
                        tooltip = (
                            <div className="tw-flex tw-flex-col tw-gap-2">
                                <div>Date Completed: 8/11/2024</div>
                                <div>Successful Remodels: 1</div>
                                <div>Failed Remodels: 0</div>
                            </div>
                        );
                    }
                    return (
                        <TableRow key={bulkCatModelJob.id}>
                            <BodyCell>
                                <div>
                                    <Tooltip content={tooltip}>
                                        <div>{bulkCatModelJob.status}</div>
                                    </Tooltip>
                                </div>
                            </BodyCell>
                            <BodyCell>{bulkCatModelJob.date_submitted}</BodyCell>
                            <BodyCell>{bulkCatModelJob.user}</BodyCell>
                            <BodyCell>{bulkCatModelJob.file_name}</BodyCell>
                            <BodyCell>
                                <BasicButton className="tw-px-2 tw-py-1" disabled={bulkCatModelJob.status !== 'Scheduled'}>
                                    Cancel
                                </BasicButton>
                            </BodyCell>
                        </TableRow>
                    );
                })}
            </Table>
        </div>
    );
};

export const BulkCatModelScheduling = () => {
    const history = useHistory();

    const { control, getValues, register, setValue } = useForm({ defaultValues: { file: null as File, scheduled_date: '' } });
    const filename = useWatch({ control })?.file?.name;

    const authorityGroupLevel = useAuthorityGroupLevel() as number;
    if (authorityGroupLevel !== AUTHORITY_GROUP_LEVELS[4]) {
        history.replace('/submissions');
        return;
    }

    return (
        <div className="tw-flex tw-flex-col tw-gap-4 tw-max-w-[800px]">
            <h2>Bulk Cat Model Scheduling</h2>
            <DragNDrop onDrop={files => setValue('file', files?.[0])}>
                <div className="tw-w-[800px] tw-h-[350px] tw-border-4 tw-border-dashed tw-flex tw-items-center tw-justify-center">
                    {!filename ? (
                        'Drag and Drop a .csv file for upload'
                    ) : (
                        <span className="tw-flex tw-gap-2">
                            Selected file:<span className="tw-font-semibold">{filename}</span>
                        </span>
                    )}
                </div>
            </DragNDrop>
            <input type="file" accept=".csv" tabIndex={0} className="tw-cursor-pointer" {...register('file')} />
            <div>
                <div className="tw-flex tw-gap-4">
                    <label>Scheduled Date</label>
                    <div className="tw-flex-1">
                        <FormDateInput name="scheduled_date" minBookingDate={new Date()} control={control} />
                    </div>
                </div>
            </div>
            <div className="tw-flex tw-gap-4">
                <BasicButton className={cx('tw-px-4 tw-py-2', { 'tw-bg-gray-400': !Object.values(getValues()).every(value => !!value) })}>
                    Schedule Bulk Cat Model
                </BasicButton>
            </div>
        </div>
    );
};
